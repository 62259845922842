import React, { useState } from "react";
import styles from "./LoginView.module.css";
import AiLogo from "../../assets/images/openAILogo.png";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../constant/Constant";
import { useMsal } from "@azure/msal-react";
import { MsalGroupConfig } from "../../constant/Constant";

function LoginView({ handleAuthentication }) {
  const { instance } = useMsal();
  const [error, setError] = useState("");
  // const account = useAccount();

  // console.log('Account', account);

  // console.log("Instance", instance);
  // console.log("accounts", accounts);
  // console.log("inProgress", inProgress);

  const navigate = useNavigate();

  const routechange = () => {
    const path = RouteNames.home;
    navigate(path);
  };

  // useEffect(() => {
  const handleGroupAuthentication = async () => {
    // if (inProgress === "signinRedirect") {
    //   return;
    // }
    try {
      // const response = await instance.acquireTokenSilent({
      //   account: accounts[0],
      //   scopes: ["openid", "profile"],
      // });
      const response = await instance.loginPopup();
      const idToken = response?.idTokenClaims;
      const userGroups = idToken?.groups || [];

      console.log('Response', response)

      if (userGroups.includes(MsalGroupConfig.groupId)) {
        localStorage.setItem("user_name", response?.account?.username);
        localStorage.setItem("name", response?.account?.name);
        localStorage.setItem('idToken', response?.idToken)

        setError("");
        handleAuthentication();
        routechange();
      } else {
        setError("User is not in the reqired group");
        await instance.loginPopup({ forceRefresh: true });
      }
    } catch (error) {
      console.error("Authentication error:", error.message);
      setError("Authentication Failed");
    }
  };

  // handleGroupAuthentication();
  // }, [instance, accounts, inProgress]);

  // const handleLogin = async () => {
  //   try {
  //     const responseMsal = await instance.loginPopup();
  //     // console.log("responseMsal", responseMsal);
  //     const groupKey = Object.keys(responseMsal?.idTokenClaims).includes(
  //       "groups"
  //     );
  //     // console.log("Group", groupKey);
  //     const groupsClaim = groupKey
  //       ? responseMsal?.idTokenClaims?.groups[0]
  //       : null;

  //     if (responseMsal && MsalGroupConfig.groupId === groupsClaim) {
  //       localStorage.setItem("user_name", responseMsal?.account?.username);
  //       setError("");
  //       handleAuthentication();
  //       routechange();
  //     } else {
  //       setError("Invalid User");
  //       await instance.loginPopup();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleLogin = async () => {
  //   try {
  //     await instance.loginPopup({ forceRefresh: true });
  //   } catch (error) {
  //     console.error("error");
  //   }
  // };

  return (
    <div className={styles["login-container"]}>
      <div className={styles.aiImgLayout}>
        <img src={AiLogo} alt="openAI" />
      </div>
      <div>
        <h6 className={styles["login-header-style"]}>Single Sign On</h6>
        <p className={styles["login-description-style"]}>
          Sign in with your identity provider
        </p>
      </div>
      <div className={styles["btn-layout"]}>
        <button
          onClick={handleGroupAuthentication}
          type="button"
          className={styles["btn-style"]}
        >
          Single Sign On(SSO)
        </button>
      </div>
      <h6 className={styles["error"]}>{error}</h6>
    </div>
  );
}

export default LoginView;
