import React, { useState } from "react";
import styles from "./HomePage.module.css";
import ChatBox from "../../components/chatBox/ChatBox";
import SidebarView from "../../components/sidebar/SidebarView";

function HomePage({handleAuthenticationOnLogout}) {
  const [openSideBar, toggleSidebar] = useState(true);

  const handleToggleSideBar = () => {
    toggleSidebar(!openSideBar);
  };

  return (
    <div className={styles["layout"]}>
      {openSideBar && (
        <div className={styles["sidebarContainer"]}>
          <SidebarView onClose={handleToggleSideBar} handleAuthenticationOnLogout={handleAuthenticationOnLogout}/>
        </div>
      )}

      <div className={styles["chatboxContainer"]}>
        <ChatBox openSideBar={openSideBar} onOpen={handleToggleSideBar} />
      </div>
    </div>
  );
}

export default HomePage;
