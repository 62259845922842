import { useContext, useState } from "react";
import styles from "./BotRating.module.css";
import { QueryDataContext } from "../../context/QueryDataContext";
import axios from "axios";
import { dateTimeFormatter } from "../../constant/Constant";
import { CircularProgress } from "@mui/material";
export default function BotRating() {
  const { userQuery, apiResponse, dispatch, rating } =
    useContext(QueryDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [botRating, setBotRating] = useState(rating);
  const {
    gpt_response = "",
    openai_exec_time = "",
    query_exec_time = "",
  } = apiResponse;

  const onRatingSubmit = async () => {
    setIsLoading(true);
    const localTimeStamp = dateTimeFormatter(
      new Intl.DateTimeFormat("en-UK", {
        dateStyle: "short",
        timeStyle: "medium",
      }).format(new Date(userQuery.query_timestamp))
    );
    const manilaTimeStamp = dateTimeFormatter(
      new Intl.DateTimeFormat("en-UK", {
        dateStyle: "short",
        timeStyle: "medium",
        timeZone: "Asia/Manila",
      }).format(new Date(userQuery.query_timestamp))
    );

    const user_name = localStorage.getItem("user_name");
    const idToken = localStorage.getItem("idToken");
    try {
      await axios.post(
        `https://oekb-func-prd-westeu-01.azurewebsites.net/api/backend_reporting_AF?code=JmyTpHBFVv3NCeYChUXMlLI60ULa1EZoBqVFeryAqnoOAzFue0DqFw==`,
        {
          Client_Query: userQuery.query,
          Bot_response: gpt_response,
          Logged_in_User: user_name,
          Query_Timestamp: localTimeStamp,
          Query_Timestamp_in_Manila: manilaTimeStamp,
          Query_Execution_Latency: query_exec_time,
          Openai_Execution_Latency: openai_exec_time,
          Rating: botRating,
          
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
      dispatch({ type: "RATING", payload: botRating });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className={styles["botRatingBox"]}>
      <span className={styles["botRatingDescription"]}>
        Please rate, How precise/correct is the current response?
      </span>
      <div className={styles["botRatingOptions"]}>
        <input
          className={styles["botRatingRadio"]}
          type="radio"
          id="botRating1"
          name="userBotRating"
          onChange={(e) => {
            setBotRating(e.target.value);
          }}
          value="1"
          checked={botRating === "1"}
        />
        <label htmlFor="botRating1">1</label>
        <input
          className={styles["botRatingRadio"]}
          type="radio"
          id="botRating2"
          name="userBotRating"
          onChange={(e) => {
            setBotRating(e.target.value);
          }}
          value="2"
          checked={botRating === "2"}
        />
        <label htmlFor="botRating2">2</label>
        <input
          className={styles["botRatingRadio"]}
          type="radio"
          id="botRating3"
          name="userBotRating"
          value="3"
          onChange={(e) => {
            setBotRating(e.target.value);
          }}
          checked={botRating === "3"}
        />
        <label htmlFor="botRating3">3</label>
        <input
          className={styles["botRatingRadio"]}
          type="radio"
          id="botRating4"
          name="userBotRating"
          value="4"
          onChange={(e) => {
            setBotRating(e.target.value);
          }}
          checked={botRating === "4"}
        />
        <label htmlFor="botRating4">4</label>
        <input
          className={styles["botRatingRadio"]}
          type="radio"
          id="botRating5"
          name="userBotRating"
          value="5"
          onChange={(e) => {
            setBotRating(e.target.value);
          }}
          checked={botRating === "5"}
        />
        <label htmlFor="botRating5">5</label>
      </div>
      {isLoading ? (
        <CircularProgress size="21px" />
      ) : (
        <button onClick={onRatingSubmit} disabled={!botRating || isLoading}>
          Submit
        </button>
      )}
    </div>
  );
}
