import styles from "./QueryMessageResponse.module.css";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ComputerIcon from "@mui/icons-material/Computer";
import { useTheme } from "../../../context/ThemeContext";
import { Link } from "react-router-dom";

function QueryMessageResponse({ msgData }) {
  const { isDarkMode } = useTheme();
  return (
    <div className={msgData.isUser ? styles["message-own"] : styles["message"]}>
      <div className={styles["messageContent"]}>
        <div className={styles["messageImg"]}>
          {msgData.isUser ? <PersonOutlineIcon /> : <ComputerIcon />}
        </div>
        <div className={styles[`${isDarkMode ? "dark" : "light"}-messageText`]}>
          {msgData?.text}
          {msgData?.references && (
            <>
              <hr></hr>
              <div className={styles["refTitle"]}>
                Top Reference Documents with Page No.
              </div>
              {msgData.references.map((ref, i) => (
                <div key={i} className={styles["refInfo"]}>
                  <Link
                    style={{ textDecoration: "none", color: "blue" }}
                    to={ref.blob_location}
                    target="_blank"
                  >
                    {ref.doc_name}
                  </Link>
                  <div>Page No:- {ref.page_number}</div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default QueryMessageResponse;
