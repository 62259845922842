import { useTheme } from "../../context/ThemeContext";
import styles from "./Suggestion.module.css";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

export default function Suggestion() {
  const { isDarkMode } = useTheme();

  return (
    <div className={`${styles["suggestionWrapper"]} ${isDarkMode ? styles['dark-suggestionWrapper'] : styles['light-suggestionWrapper']}` }>
      <WarningRoundedIcon sx={{ color: isDarkMode ? 'yellow' : "red" }} />
      <div className={`${styles["suggestionText"]} ${isDarkMode ? styles['dark-suggestionText'] : styles['light-suggestionText']}`}>
        Could you kindly Rate the feedback on the responses provided below
        (1-Low / 5-High)
      </div>
    </div>
  );
}
