import React from "react";
import DocumentTable from "../../document_table/DocumentTable";
import { Box, Modal } from "@mui/material";

function TableModal({style, handleClose, open }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DocumentTable/>
        </Box>
      </Modal>
    </div>
  );
}

export default TableModal;
