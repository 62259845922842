import React, { useEffect, useState } from "react";
import Login from "./pages/login/Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { MsalGroupConfig, RouteNames } from "./constant/Constant";
import HomePage from "./pages/home/HomePage";
import { useMsal } from "@azure/msal-react";
import ErrorPage from "./pages/error/ErrorPage";

function App() {
  const { instance } = useMsal();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthentication = () => {
    setIsAuthenticated(true);
  };

  const handleAuthenticationOnLogout = () => {
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      const accounts = instance.getAllAccounts();

      const groupKey =
        accounts.length > 0
          ? Object.keys(accounts[0]?.idTokenClaims).includes("groups")
          : null;
      // console.log("Group", groupKey);
      const groupsClaim = groupKey
        ? accounts[0]?.idTokenClaims?.groups[0]
        : null;
      if (accounts.length > 0 && MsalGroupConfig.groupId === groupsClaim) {
        // User is authenticated
        setIsAuthenticated(true);
      } else {
        // User is not authenticated
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to={RouteNames.home} />
            ) : (
              <Login handleAuthentication={handleAuthentication} />
            )
          }
        />

        <Route
          path={RouteNames.home}
          element={
            isAuthenticated ? (
              <HomePage
                handleAuthenticationOnLogout={handleAuthenticationOnLogout}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
