import { createContext, useContext, useState } from "react";

const ThemeContext = createContext();

export const useTheme = () => {

    const context = useContext(ThemeContext)

    if(!context){
        throw new Error('useTheme must be used within a ThemeProvider')
    }

    return context;

};

export const ThemeProvider = ({children}) => {
    const [isDarkMode, setDarkMode] = useState(false)

    const toggleTheme = () => {
        setDarkMode(prevMode => !prevMode);
    }

    return (
        <ThemeContext.Provider value={{isDarkMode, toggleTheme}}> 
            {children}
        </ThemeContext.Provider>
    )
}

