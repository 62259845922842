import React, { useContext, useState } from "react";
import styles from "./SidebarView.module.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import aiImg from "../../assets/images/openAI.png";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LogoutModal from "../UI/logout_modal/LogoutModal";
import ThemeModal from "../UI/theme_modal/ThemeModal";
import TableModal from "../UI/table_modal/TableModal";
import { QueryDataContext } from "../../context/QueryDataContext";
import { useTheme } from "../../context/ThemeContext";

const logOutModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "18%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  outline: 0,
};

const colorSchemeModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "12%",
  bgcolor: "#D9D9D9",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 3,
  p: 3,
  outline: 0,
};

const tableModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 3,
  p: "10px",
  outline: 0,
};

function Sidebar({ onClose, handleAuthenticationOnLogout }) {
  const { apiResponse } = useContext(QueryDataContext);
  const [openLogout, setOpenLogout] = useState(false);
  const [openColorScheme, setOpenColorScheme] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();

  const currentUser = localStorage.getItem("name");

  const handleOpenLogout = () => {
    setOpenLogout(true);
  };

  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  const handleOpenColorScheme = () => {
    setOpenColorScheme(true);
  };

  const handleCloseColorScheme = () => {
    setOpenColorScheme(false);
  };

  const handleShowTable = () => {
    setShowTable(true);
  };

  const handleCloseTable = () => {
    setShowTable(false);
  };

  return (
    <div
      className={`${styles["sidebar"]} ${
        isDarkMode ? styles["dark-sidebar"] : styles["light-sidebar"]
      }`}
    >
      <div>
        <button
          type="button"
          onClick={onClose}
          className={styles["closeIconStyle"]}
        >
          <ClearOutlinedIcon
            sx={{ color: `${isDarkMode ? "white" : "black"}` }}
          />
        </button>
      </div>
      <div style={{ padding: "0 5px 0 5px" }}>
        <img className={styles.aiImg} src={aiImg} alt="openAI" />
      </div>

      <p
        className={`${styles.headText} ${
          isDarkMode ? styles["dark-headText"] : styles["light-headText"]
        }`}
      >
        Welcome {currentUser}
      </p>

      <div className={styles.btnLayout}>
        {apiResponse?.data?.length > 0 && (
          <button
            onClick={handleShowTable}
            type="button"
            className={`${styles.btnStyle} ${
              isDarkMode ? styles["dark-btnStyle"] : styles["light-btnStyle"]
            }`}
          >
            Reference Documents
          </button>
        )}
      </div>
      <div className={styles.layoutPosition}>
        <div onClick={handleOpenColorScheme} className={styles.settingLayout}>
          <SettingsOutlinedIcon
            sx={{ color: isDarkMode ? "#ffffff" : "#000000" }}
          />
          <h6
            className={`${styles.settingStyle} ${
              isDarkMode
                ? styles["dark-settingStyle"]
                : styles["light-settingStyle"]
            }`}
          >
            Theme
          </h6>
        </div>
        <div onClick={handleOpenLogout} className={styles.logoutLayout}>
          <LogoutOutlinedIcon
            sx={{ color: isDarkMode ? "#ffffff" : "#000000" }}
          />
          <h6
            className={`${styles.logoutStyle} ${
              isDarkMode
                ? styles["dark-logoutStyle"]
                : styles["light-logoutStyle"]
            }`}
          >
            Logout
          </h6>
        </div>
      </div>

      {openLogout ? (
        <LogoutModal
          open={openLogout}
          handleClose={handleCloseLogout}
          header="Logout"
          description="Are you Sure you want to Logout?"
          style={logOutModalStyle}
          handleAuthenticationOnLogout={handleAuthenticationOnLogout}
        />
      ) : null}
      {openColorScheme ? (
        <ThemeModal
          open={openColorScheme}
          handleClose={handleCloseColorScheme}
          header="Theme"
          style={colorSchemeModalStyle}
          toggleTheme={toggleTheme}
          isDarkMode={isDarkMode}
        />
      ) : null}
      {showTable ? (
        <TableModal
          open={showTable}
          style={tableModalStyle}
          handleClose={handleCloseTable}
        />
      ) : null}
    </div>
  );
}

export default Sidebar;
