const QueryReducer = (state, action) => {
  switch (action.type) {
    case "QUERY_START":
      return {
        messages: [
          ...state.messages,
          { text: action.payload.query, isUser: true },
        ],
        userQuery: action.payload,
        apiResponse: null,
        isFetching: true,
        error: false,
        rating: "",
      };
    case "QUERY_SUCCESS":
      return {
        messages: [
          ...state.messages,
          {
            text: action.payload["gpt_response"],
            isUser: false,
            references: action.payload["data"].slice(0, 2),
          },
        ],
        userQuery: state.userQuery,
        apiResponse: action.payload,
        isFetching: false,
        error: false,
        rating: "",
      };
    case "QUERY_FAILURE":
      return {
        messages: [
          ...state.messages,
          { text: "Oops, Something went wrong", isUser: false },
        ],
        userQuery: { query: "", query_timestamp: "" },
        apiResponse: null,
        isFetching: false,
        error: action.payload,
        rating: "",
      };
    case "RATING":
      return {
        ...state,
        rating: action.payload,
      };
    default:
      return state;
  }
};

export default QueryReducer;
