export const RouteNames = {
  home: "/home",
};

export const QUERY_CONTEXT_INITIAL_STATE = {
  isFetching: false,
  error: false,
  rating: "",
  messages: [
    {
      text: "Hello! How Can I help you?",
      isUser: false,
    },
  ],
  userQuery: { query: "", query_timestamp: "" },
  apiResponse: {
    column_name: [],
    data: [],
    error: "",
    gpt_response: "",
    openai_exec_time: "",
    query_exec_time: "",
    status_code: 0,
  },
};

export const dateTimeFormatter = (date) => {
  const [dateString, timeString] = date.split(",");
  const [dd, mm, yyyy] = dateString.split("/");

  return `${yyyy}-${mm}-${dd}${timeString}`;
};

export const MsalGroupConfig = {
  groupId: "1c70e5b8-3d90-4931-94bf-1bbf4e1fe48d"
}
 

