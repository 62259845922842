
const msalConfig = {
    auth: {
      clientId: '10909812-7339-4c77-b617-93c87286f1f4',
      authority: 'https://login.microsoftonline.com/170fc8db-28be-4876-ad4f-94e984183a7b',
      redirectUri: "https://pal-openai.inspiro.com", // Update with your redirect URI
      postLogoutRedirectUri: "https://pal-openai.inspiro.com", // Indicates the page to navigate after logout.
      // redirectUri: "/", // Update with your redirect URI
      // postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  };
  
  export default msalConfig;