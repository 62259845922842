import classes from "./errorPage.module.css";

export default function ErrorPage() {
  return (
    <div className={classes.center}>
      <h1>404: Page Not Found</h1>
      <div>
        <p>
          Please contact <b>Admin</b>
        </p>
      </div>
    </div>
  );
}
