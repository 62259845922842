import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "./context/ThemeContext";
import { QueryDataContextProvider } from "./context/QueryDataContext";
import { PublicClientApplication } from "@azure/msal-browser";
import msalConfig from "./msalAuth.Config";
import { MsalProvider } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider>
        <QueryDataContextProvider>
          <App />
        </QueryDataContextProvider>
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
);
