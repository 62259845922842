import React from "react";
import Modal from "@mui/material/Modal";
import styles from "./LogoutModal.module.css";
import { Box } from "@mui/material";
import { useMsal } from "@azure/msal-react";

function LogoutModal({
  open,
  handleClose,
  header,
  description,
  style,
  handleAuthenticationOnLogout,
}) {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logout();
    localStorage.clear();
    handleAuthenticationOnLogout();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h6 id="modal-modal-title" className={styles.headerStyle}>
            {header}
          </h6>
          <p className={styles.contentStyle} id="modal-modal-description">
            {description}
          </p>

          <div className={styles.btnContainerLayout}>
            <button
              onClick={handleClose}
              className={styles.noBtnLayout}
              type="button"
            >
              No
            </button>
            <button
              onClick={handleLogout}
              className={styles.yesBtnLayout}
              type="button"
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default LogoutModal;
