import { useContext, useRef } from "react";
import BotResponse from "../botResponse/BotResponse";
import Suggestion from "../suggestion/Suggestion";
import styles from "./ChatBox.module.css";
import TelegramIcon from "@mui/icons-material/Telegram";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useTheme } from "../../context/ThemeContext";
import { QueryDataContext } from "../../context/QueryDataContext";
import axios from "axios";
import BotRating from "../botRating/BotRating";

export default function ChatBox({ openSideBar, onOpen }) {
  const { isFetching, rating, apiResponse, dispatch } =
    useContext(QueryDataContext);
  const { isDarkMode } = useTheme();
  const query = useRef();
  const idToken = localStorage.getItem('idToken');

  const onSubmitQuery = async (e) => {
    e.preventDefault();
    const userQuery = query.current.value;
    if (userQuery) {
      const queryTimestamp = new Date();
      dispatch({
        type: "QUERY_START",
        payload: {
          query: userQuery,
          query_timestamp: queryTimestamp.toISOString(),
        },
      });
      query.current.value = "";
      try {
        const res = await axios.get(
          `https://oekb-func-prd-westeu-01.azurewebsites.net/api/backend_AF?code=jxRlzg67SZjwy-oSdV21ZU7A38xAsNlSm3q0AYzX19s7AzFuD6rSqQ==&query=${userQuery}`,{
            headers: {
              'Authorization': idToken
            }}
        );
        dispatch({ type: "QUERY_SUCCESS", payload: res.data });
      } catch (error) {
        dispatch({ type: "QUERY_FAILURE", payload: error });
        console.log(error);
      }
    }
  };

  return (
    <div
      className={
        openSideBar
          ? styles["chatBoxWithSidebar"]
          : styles["chatBoxWithoutSidebar"]
      }
    >
      <div
        className={`${styles["chatboxWrapper"]} ${
          isDarkMode
            ? styles["dark-chatboxWrapper"]
            : styles["light-chatboxWrapper"]
        }`}
      >
        <div className={styles["conversationTop"]}>
          {!openSideBar && (
            <div>
              <ArrowForwardIosOutlinedIcon onClick={onOpen} />
            </div>
          )}
          {!rating && apiResponse?.data.length > 0 && (
            <div style={{ flex: 1 }}>
              <Suggestion />
            </div>
          )}
        </div>
        <div className={styles["conversation"]}>
          <BotResponse />
        </div>
        {!rating && apiResponse?.data.length > 0 && <BotRating />}
        <hr className={styles["chatBoxHr"]} />
        <form onSubmit={onSubmitQuery} className={styles["queryBox"]}>
          <div
            className={`${styles["userInputPrompt"]} ${
              styles[`${isDarkMode ? "dark" : "light"}-userInputPrompt`]
            }`}
          >
            Enter your Queries on open ai search engine
          </div>
          <div className={styles["userInputField"]}>
            <input
              disabled={
                (apiResponse?.gpt_response || isFetching) &&
                (!rating || isFetching)
              }
              type="text"
              ref={query}
              className={`${styles["inputField"]} ${
                isDarkMode
                  ? styles["dark-inputField"]
                  : styles["light-inputField"]
              }`}
              placeholder="Type Your Query!!"
            />
            <button
              disabled={
                (apiResponse?.gpt_response || isFetching) &&
                (!rating || isFetching)
              }
              type="submit"
              className={styles["sendButton"]}
            >
              <TelegramIcon sx={{ fontSize: "30px" }} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
