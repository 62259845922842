import React from "react";
import styles from "./Login.module.css";
import LoginView from "../../components/login_view/LoginView";

function Login({handleAuthentication}) {
  return (
    <div className={styles["login-container-layout"]}>
      <div className={styles["login-layout"]}>
        <LoginView handleAuthentication={handleAuthentication}/>
      </div>
    </div>
  );
}

export default Login;
