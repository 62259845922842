import React, { useContext } from "react";
import styles from "./DocumentTable.module.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { QueryDataContext } from "../../context/QueryDataContext";

function DocumentTable() {
  const { apiResponse } = useContext(QueryDataContext);
  return (
    <TableContainer className={styles.tablContLayout}>
      <Table>
        <TableHead>
          <TableRow className={styles.tableColHeaderStyle}>
            {apiResponse?.column_name?.length > 0
              ? apiResponse?.column_name.map((ele) => (
                  <TableCell
                    key={ele.column_name}
                    className={styles.tableColHeaderTextStyle}
                  >
                    {ele.column_name}
                  </TableCell>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {apiResponse?.data?.length > 0
            ? apiResponse?.data?.map((data, i) => (
                <TableRow
                  key={data.uid}
                  className={`${
                    i % 2 === 0
                      ? styles["table-Data-White-Bg-Color"]
                      : styles["table-Data-grey-Bg-Color"]
                  }`}
                >
                  <TableCell
                    className={`${
                      i % 2 === 0
                        ? styles["table-Data-grey-text-Color"]
                        : styles["table-Data-green-text-Color"]
                    }`}
                  >
                    {data.page_number}
                  </TableCell>
                  <TableCell
                    className={`${
                      i % 2 === 0
                        ? styles["table-Data-grey-text-Color"]
                        : styles["table-Data-green-text-Color"]
                    }`}
                  >
                    {data.doc_name}
                  </TableCell>
                  <TableCell
                    className={`${
                      i % 2 === 0
                        ? styles["table-Data-grey-text-Color"]
                        : styles["table-Data-green-text-Color"]
                    }`}
                  >
                    <a target="_blank" href={data.blob_location}>
                      Click here to open document
                    </a>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DocumentTable;
