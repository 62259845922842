import React from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

function ThemeModal({
  open,
  handleClose,
  header,
  style,
  toggleTheme,
  isDarkMode,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{header}</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Light"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value={isDarkMode}
                control={<Radio />}
                label="Light"
                onChange={toggleTheme}
                checked={isDarkMode === false}
              />
              <FormControlLabel
                value={isDarkMode}
                control={<Radio />}
                label="Dark"
                onChange={toggleTheme}
                checked={isDarkMode === true}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Modal>
    </div>
  );
}

export default ThemeModal;
