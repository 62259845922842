import React, { useContext, useEffect, useRef } from "react";
import styles from "./BotResponse.module.css";
import { QueryDataContext } from "../../context/QueryDataContext";
import QueryMessageResponse from "../message/query_message_response/QueryMessageResponse";

export default function BotResponse() {
  const { messages, isFetching } = useContext(QueryDataContext);
  const scrollRef = useRef();
  useEffect(() => {
    scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  const LoadingAnimation = () => {
    return <div className={styles["loading"]}>Loading...</div>;
  };
  return (
    <div className={styles["botResponse"]}>
      {messages.map((message, i) => (
        <div key={i} ref={scrollRef}>
          <QueryMessageResponse key={i} msgData={message} />
        </div>
      ))}
      {isFetching && (
        <QueryMessageResponse
          msgData={{ text: <LoadingAnimation />, isUser: false }}
        />
      )}
    </div>
  );
}
