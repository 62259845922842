import { createContext, useReducer } from "react";
import { QUERY_CONTEXT_INITIAL_STATE } from "../constant/Constant";
import QueryReducer from "./QueryReducer";

export const QueryDataContext = createContext(QUERY_CONTEXT_INITIAL_STATE);

export const QueryDataContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    QueryReducer,
    QUERY_CONTEXT_INITIAL_STATE
  );

  return (
    <QueryDataContext.Provider
      value={{
        isFetching: state.isFetching,
        error: state.error,
        rating: state.rating,
        userQuery: state.userQuery,
        apiResponse: state.apiResponse,
        messages: state.messages,
        dispatch,
      }}
    >
      {children}
    </QueryDataContext.Provider>
  );
};
